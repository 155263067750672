var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.data
      ? _c(
          "div",
          [
            _c(
              "a-form",
              _vm._b(
                { attrs: { layout: "vertical", form: _vm.form } },
                "a-form",
                _vm.formItemLayout,
                false
              ),
              [
                _c(
                  "a-row",
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 12, xs: 24, s: 24, md: 24, lg: 12 } },
                      [
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { "margin-top": "1rem" },
                            attrs: {
                              label: _vm.$t(_vm.formRules.equipment.label)
                            }
                          },
                          [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.equipment.decorator,
                                  expression: "formRules.equipment.decorator"
                                }
                              ],
                              attrs: {
                                disabled: "",
                                name: _vm.formRules.equipment.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.equipment.placeholder
                                )
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { "margin-top": "1rem" },
                            attrs: { label: _vm.$t(_vm.formRules.merk.label) }
                          },
                          [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.merk.decorator,
                                  expression: "formRules.merk.decorator"
                                }
                              ],
                              attrs: {
                                disabled: "",
                                name: _vm.formRules.merk.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.merk.placeholder
                                )
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { "margin-top": "1rem" },
                            attrs: { label: _vm.$t(_vm.formRules.type.label) }
                          },
                          [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.type.decorator,
                                  expression: "formRules.type.decorator"
                                }
                              ],
                              attrs: {
                                disabled: "",
                                name: _vm.formRules.type.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.type.placeholder
                                )
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { "margin-top": "1rem" },
                            attrs: { label: _vm.$t(_vm.formRules.snAsli.label) }
                          },
                          [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.snAsli.decorator,
                                  expression: "formRules.snAsli.decorator"
                                }
                              ],
                              attrs: {
                                disabled: "",
                                name: _vm.formRules.snAsli.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.snAsli.placeholder
                                )
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { "margin-top": "1rem" },
                            attrs: {
                              label: _vm.$t(_vm.formRules.kodeUnit.label)
                            }
                          },
                          [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.kodeUnit.decorator,
                                  expression: "formRules.kodeUnit.decorator"
                                }
                              ],
                              attrs: {
                                disabled: "",
                                name: _vm.formRules.kodeUnit.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.kodeUnit.placeholder
                                )
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "a-form-model-item",
                          {
                            staticStyle: { "margin-top": "1rem" },
                            attrs: {
                              label: _vm.$t(_vm.formRules.kapasitas.label)
                            }
                          },
                          [
                            _c("a-input", {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.kapasitas.decorator,
                                  expression: "formRules.kapasitas.decorator"
                                }
                              ],
                              attrs: {
                                disabled: "",
                                name: _vm.formRules.kapasitas.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.kapasitas.placeholder
                                )
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _c(
          "div",
          [
            _c("a-result", {
              attrs: {
                status: "404",
                title: "404",
                "sub-title": "Sorry, the page you visited does not exist."
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }