





























































































import Vue from "vue";
import { qrServices } from "@/services/qr.services";
import { ResponseDetailQR } from "@/models/interface/qr.interface";
import { Result } from "ant-design-vue";
import { debounce } from "@/helpers/debounce";
Vue.use(Result);
export default Vue.extend({
  data() {
    return {
      form: this.$form.createForm(this, { name: "detailQr" }),
      data: null as ResponseDetailQR | null,
      formRules: {
        equipment: {
          label: "Equipment",
          name: "equipment",
          placeholder: "Input Equipment",
          decorator: ["equipment"],
        },
        merk: {
          label: "Merk",
          name: "merk",
          placeholder: "Input Merk",
          decorator: ["merk"],
        },
        type: {
          label: "lbl_type",
          name: "type",
          placeholder: "Input Type",
          decorator: ["type"],
        },
        snAsli: {
          label: "SN",
          name: "snAsli",
          placeholder: "Input SN",
          decorator: ["snAsli"],
        },
        kodeUnit: {
          label: "Kode Unit",
          name: "kodeUnit",
          placeholder: "Input Kode Unit",
          decorator: ["kodeUnit"],
        },
        kapasitas: {
          label: "Kapasitas",
          name: "kapasitas",
          placeholder: "Input Kapasitas",
          decorator: ["kapasitas"],
        },
      },
    };
  },
  methods: {
    checkData(value): string {
      if (value) return value;
      else return "-";
    },
  },
  created() {
    const unitCode = this.$route.params.id;
    // let params = {} as RequestQueryParamsModel
    qrServices
      .getDetailQR(unitCode)
      .then(data => {
        this.data = data;
        debounce(() => {
          this.form.setFieldsValue({
            merk: this.checkData(data.brand),
            type: this.checkData(data.type),
            snAsli: this.checkData(data.serialNumber),
            kodeUnit: this.checkData(data.unitCode),
            kapasitas: this.checkData(data.capacity),
            equipment: this.checkData(data.equipment),
          });
        }, 500);
        // merk, type, SN asli, kode unit, kapasitas,
      })
      .catch(error => {
        if (error.status === 404) {
          qrServices
            .getDetailQrAssetAddition(unitCode)
            .then(dataDetailAssetAddition => {
              this.data = dataDetailAssetAddition;
              debounce(() => {
                this.form.setFieldsValue({
                  merk: this.checkData(dataDetailAssetAddition.brand),
                  type: this.checkData(dataDetailAssetAddition.type),
                  snAsli: this.checkData(dataDetailAssetAddition.serialNumber),
                  kodeUnit: this.checkData(dataDetailAssetAddition.unitCode),
                  kapasitas: this.checkData(dataDetailAssetAddition.capacity),
                  equipment: this.checkData(dataDetailAssetAddition.equipment),
                });
              }, 500);
            })
            .catch(() => {
              this.data = null;
            });
        } else {
          this.data = null;
        }
      });
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
});
