// Core
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { ResponseDetailQR } from "@/models/interface/qr.interface";

export class QRServices extends HttpClient {
  getDetailQR = (id: string): Promise<ResponseDetailQR> => {
    return this.get<ResponseDetailQR>(Api.QR + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getDetailQrAssetAddition = (id: string): Promise<ResponseDetailQR> => {
    return this.get<ResponseDetailQR>(Api.QRAssetAddition + "/" + id)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}

export const qrServices = new QRServices();
